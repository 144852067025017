import { ICatalogSection } from '@app/core/interfaces/catalogCreatorSection.interface';
import { createAction, props } from '@ngrx/store';

export const addSection = createAction('[Catalog Section] Add Section', props<{ section: ICatalogSection }>());

export const updateSection = createAction('[Catalog Section] Update Section', props<{ section: ICatalogSection }>());

export const removeSection = createAction('[Catalog Section] Remove Section', props<{ sectionId: number }>());

export const clearAllSection = createAction('[Catalog Section] ClearAll Section ');

export const removeProductFromSection = createAction(
  '[Catalog Section] Remove Product From Section ',
  props<{ sectionId: number; productId: string }>()
);
